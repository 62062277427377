html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background-color: white;
  overflow-x: hidden;
}

#root {
  height: 100%;
  margin: 0;
  padding: 0;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: white;
}

header {
  display: flex;
  padding: 20px 30px;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  background-color: white;
}

main {
  display: flex;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  align-items: flex-start;
  justify-content: center;
  background-color: white;
}
