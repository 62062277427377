.landing-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
  height: 100%;
  max-width: 800px;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 768px) {
  .landing-content {
    padding: 0 20px;
  }
}

.landing-form {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  max-width: 400px;
  justify-content: center;
  align-items: center;
}

.landing-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.email-input-container {
  font-family: "Source Sans 3";
  font-size: 16px;
  width: 100%;
  font-size: 14px;
}

.email-input-field {
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  border: 1px solid #000;
  border-radius: 4px;
  outline: none;
}

.email-input-field:focus {
  border-color: #77bc1f;
  border-radius: 4px;
  border-width: 2px;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.15);
}
