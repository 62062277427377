.calendly-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 800px;
  height: auto;
  background-color: #f8f9fa;
  border-radius: 10px;
  padding-bottom: 16px;
  position: relative;
  overflow: hidden;
  gap: 16px;
}

@media screen and (max-width: 768px) {
  .calendly-modal-content {
    max-width: 90%;
    max-height: 90%;
  }
}
