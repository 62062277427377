* {
  box-sizing: border-box;
}

.results-view-container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 30px 60px;
  gap: 30px;
}

#tabs {
  display: flex;
  flex-direction: row;
  gap: 40px;
}

#tab {
  display: flex;
  height: 26px;
  justify-content: center;
  align-items: center;
  gap: 0px;
  flex: 1 0 0;
  align-self: stretch;
  cursor: pointer;
  padding: 0;
  margin-top: 3px;
}

.tab-selected {
  border-bottom: 3px solid #77bc1f;
  color: black;
}

.tab-unselected {
  color: #d9d9d9;
}

.content-header {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}

@media screen and (max-width: 768px) {
  .results-view-container {
    padding: 10px;
    flex-direction: column;
    width: 100%;
    height: auto;
  }

  .content-header {
    flex-direction: column;
    gap: 10px;
  }
}
