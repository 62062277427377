.table-row {
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid #d9dadb;
  border-radius: 10px;
  gap: 20px;
  padding: 10px 10px 10px 20px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
}

.table-row-skeleton {
  width: 100%;
  height: 15px;
  background-color: #d9dadb;
}

.dash {
  width: 100%;
  height: 2px;
  background-color: #d9dadb;
}

.view-classification-button {
  font-family: "Source Sans 3";
  color: white;
  font-weight: 400;
  font-size: 16px;
  background-color: #77bc1f;
  background-blend-mode: multiply;
  border: none;
  border-radius: 6px;
  padding: 6px 16px 6px 16px;
  cursor: pointer;
}

.table-row-text {
  align-self: stretch;
  overflow: hidden;
  color: var(--neutral-structure-gray-900, #333435);
  text-overflow: ellipsis;
  /* Mobile/B1/Link */
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-align: left;
}

.classification-icons-mobile {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.mobile-icons {
  display: flex;
  align-items: center;
  gap: 8px;
}

.mobile-card {
  display: flex;
  width: 100%;
  padding: 16px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid var(--neutral-structure-gray-100, #d9dadb);
  background: var(--bw-white-0, #fff);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
}
