.dashboard-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 75%;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.dashboard-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  height: 100%;
  align-self: stretch;
  padding: 30px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  gap: 24px;
}

.dashboard-left {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  width: 66%;
  gap: 20px;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.15);
  padding: 30px;
}

.regulation-count-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 45%;
  margin-top: 30px;
  gap: 48px;
}

.dashboard-left-header {
  width: 100%;
  color: var(--primary-green-200, #77bc1f);
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  text-align: left;
}

.total-products-text {
  color: var(--bw-black-1000, #000);
  font-family: Source Sans Pro;
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: 81.25%;
}

.timer-card {
  display: flex;
  padding: 6px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  border: 1px solid var(--primary-green-200, #77bc1f);
  background: var(--bw-white-0, #fff);
}

.timer-text {
  color: var(--primary-green-200, #77bc1f);
  text-align: center;
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  text-transform: uppercase;
}

.pie-chart-legend {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  align-self: stretch;
}

.pie-chart-legend-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
}

.pie-chart-legend-number {
  font-family: Source Sans Pro;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 108.333%;
}

.product-count-container {
  display: flex;
  width: 45%;
  height: 100%;
  padding: 56px 32px 32px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: 10px;
  background: var(--primary-green-50, #e7f8cb);
}

.dashboard-right-header {
  align-self: stretch;
  color: var(--bw-black-1000, #000);
  font-family: Source Sans Pro;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
}

.dashboard-right-text {
  align-self: stretch;
  color: var(--bw-black-1000, #000);
  /* Desktop/B1/Regular */
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .dashboard-container {
    flex-direction: column;
    height: auto;
    align-items: flex-start;
    gap: 24px;
    padding: 10px 0px;
    align-self: stretch;
  }

  .dashboard-left {
    flex-direction: row;
    align-items: flex-start;
    height: auto;
    width: 100%;
  }

  .dashboard-right {
    width: 100%;
    padding: 20px;
  }

  .regulation-count-container {
    width: 100%;
    height: auto;
    gap: 20px;
  }

  .product-count-container {
    width: 100%;
    height: auto;
    padding: 20px;
    margin: 0px;
  }
}
