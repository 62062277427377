.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  gap: 6px;
  margin-top: 100px;
}

#loading-bar-background {
  height: 6px;
  width: 100%;
  max-width: 500px;
  border-radius: 3px;
  background-color: #d9dadb;
}

#loading-bar {
  height: 6px;
  background-color: #77bc1f;
  width: 0%;
  border-radius: 3px;
}

.step-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fade-in {
  animation-name: fadeIn;
  animation-duration: 7s;
  animation-fill-mode: forwards;
}

.kpi-container {
  display: flex;
  flex-direction: row;
  gap: 24px;
}

@media screen and (max-width: 768px) {
  .kpi-container {
    flex-direction: column;
    align-items: center;
  }
}

.kpi-group {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 24px;
}

.kpi {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
  width: 100%;
  width: 120px;
}

.sorty-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: 100%;
  margin-top: 24px;
}
