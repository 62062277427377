.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: white;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
}

.modal-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 800px;
  background-color: #f8f9fa;
  border-radius: 10px;
  padding-bottom: 16px;
  position: relative;
  overflow: hidden;
  gap: 16px;
}

.close-button {
  background-color: transparent;
  cursor: pointer;
  font-size: 16px;
  font-weight: normal;
  color: black;
  text-decoration-line: underline;
  margin-right: 10px;
}

.close-button:hover {
  background-color: #f8f9fa;
}

.modal-card {
  display: flex;
  flex-direction: column;
  width: 95%;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.15);
  gap: 24px;
}

.metric {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  min-width: 196px;
  flex-direction: column;
  align-items: flex-start;
}

.standout-background {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #e7f8cb;
  border-radius: 20px;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.15);
  padding: 3px 20px;
}

.standout-metric {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  min-width: 196px;
  flex-direction: column;
  align-items: flex-start;
}

.card-row {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.item-label {
  font-family: "Source Sans 3";
  font-size: 14px;
  font-weight: normal;
  padding: 0;
  margin: 0;
  text-align: center;
  color: #818283;
}

.standout-item-label {
  font-family: "Source Sans 3";
  font-size: 14px;
  font-weight: 600;
  padding: 0;
  margin: 0;
  text-align: center;
  color: #77bc1f;
}

.item-text {
  font-family: "Source Sans 3";
  font-size: 16px;
  font-weight: normal;
  padding: 0;
  margin: 0;
  text-align: left;
}

.standout-item-text {
  font-family: "Source Sans 3";
  font-size: 20px;
  font-weight: normal;
  padding: 0;
  text-align: left;
}

.smarter-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 95%;
  background-color: white;
  border-radius: 10px;
  padding: 24px;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.15);
  gap: 24px;
  border: 1px solid #77bc1f;
}

.transport-row {
  display: flex;
  align-items: flex-start;
  gap: 24px;
}

.transport-container {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}

.transport-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 196px;
  gap: 4px;
}

@media screen and (max-width: 768px) {
  .modal-overlay {
    align-items: flex-start;
    justify-content: flex-start;
  }

  .modal-content {
    max-height: 90%;
    max-width: 90%;
    overflow-y: scroll;
  }

  .modal-header {
    padding-top: 20px;
  }

  .card-row {
    gap: 0px;
  }

  .transport-row {
    flex-direction: column;
    gap: 15px;
  }
}
