.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 20px;
}

.footer-container {
  display: flex;
  flex-direction: row;
  height: 20px;
  justify-content: flex-start;
  gap: 10px;
}

.footer-text {
  color: var(--Black, #000);
  /* Text/Small/Normal */
  font-family: Source Sans Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
}

.footer-url {
  color: var(--Black, #000);
  /* Text/Small/Link */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  text-decoration-line: underline;
}

@media screen and (max-width: 768px) {
  .footer {
    padding: 0px 0px 10px 10px;
    position: relative;
    margin-top: 90px;
  }
}
