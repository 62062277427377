.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-overlay-mobile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}

.pricing-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  max-width: 900px;
  height: auto;
  background-color: #fff;
  border-radius: 10px;
  padding: 32px 56px;
  position: relative;
  overflow: hidden;
  gap: 32px;
}

.pricing-modal-mobile {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  height: 90%;
  max-width: 900px;
  background-color: #fff;
  border-radius: 10px;
  padding: 32px 56px;
  position: relative;
  overflow: auto;
  gap: 32px;
  margin: 5px 16px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.pricing {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0px;
  gap: 16px;
}

.pricing-mobile {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0px;
  gap: 24px;
}

.plan {
  flex: 1;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  gap: 16px;
}

.row-labels {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 20%;
  gap: 16px;
}

.pricing-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 25%;
  gap: 16px;
}

.pricing-card-mobile {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 16px;
}
