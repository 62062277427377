/* Modal.css */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.cta-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  max-width: 700px;
  height: auto;
  background-color: #fff;
  border-radius: 10px;
  padding: 32px 56px;
  position: relative;
  overflow: hidden;
  gap: 16px;
}

.close-button-bottom {
  background-color: transparent;
  cursor: pointer;
  font-size: 16px;
  font-weight: normal;
  color: black;
  text-decoration-line: underline;
}

.close-button-bottom:hover {
  background-color: #f8f9fa;
}

.check-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
