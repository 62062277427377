.items-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin-top: 40px;
}

.table-body {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

@media screen and (max-width: 768px) {
  .items-table {
    margin: 0px;
    padding: 10px;
  }
}

.table-header {
  display: flex;
  width: 100%;
  gap: 20px;
  padding: 0px 20px 4px 20px;
}

.classification-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 40px;
}

.highlight {
  position: absolute;
  right: 198px;
  top: -20px;
  bottom: 0;
  width: 230px;
  background-color: #77bc1f;
  opacity: 0.1;
  border-radius: 10px 10px 0px 0px;
}

.ai-logo {
  position: absolute;
  right: 250px;
  top: -60px;
  width: 120px;
}

.greyed-out {
  position: relative;
  z-index: 1;
  opacity: 0.5;
  background: #f3f3f3; /* You can adjust this value */
  transition: opacity 0.3s ease;
}

.overlay-container {
  position: relative;
}

.overlay {
  position: absolute;
  top: -2px;
  left: -2px;
  right: 0px;
  bottom: 0;
  background: #d9d9d9cc;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 60px;
  z-index: 2;
  border-radius: 12px;
  gap: 16px;
}

.overlay-button-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
