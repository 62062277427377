.logo-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.demo-logo-container {
  display: flex;
  justify-content: center;
  gap: 4px;
}

.demo-logo-text {
  color: var(--primary-green-200, #77bc1f);
  /* Desktop/B1/Emphasis */
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.return-link {
  color: var(--bw-black-1000, #000);
  text-align: center;
  /* Desktop/B1/Link */
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  text-decoration-line: underline;
}

.header-container-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.header-row-mobile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 10px;
}
