h1 {
  font-family: Source Sans Pro;
  font-size: 62px;
  font-style: normal;
  font-weight: 200;
  line-height: 120%;
  padding: 0;
  margin: 0;
  text-align: center;
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 42px;
  }
}

h2 {
  font-family: Source Sans Pro;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 768px) {
  h2 {
    font-size: 24px;
  }
}

h3 {
  font-family: Source Sans Pro;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 133.333%;
  padding: 0px 20px 0px 20px;
  margin: 0;
  text-align: center;
}

h4 {
  font-family: Source Sans Pro;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

p {
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  padding: 0;
  margin: 0;
  text-align: left;
}

td {
  text-align: left;
  padding: 0px;
  font-family: "Source Sans 3";
  font-weight: normal;
  font-size: 16px;
}

thead {
  width: 100%;
  padding: 0px 0px 0px 20px;
}

th {
  font-family: "Source Sans 3";
  font-weight: normal;
  font-size: 16px;
  color: #818283;
  text-align: left;
  padding: 0px 0px 4px 0px;
}

button {
  font-family: "Source Sans Pro";
  color: white;
  font-weight: 600;
  font-size: 16px;
  background-color: #77bc1f;
  border: none;
  border-radius: 10px;
  padding: 6px 16px 6px 16px;
  cursor: pointer;
}

.button-text {
  color: var(--bw-white-0, #fff);
  text-align: center;
  /* Desktop/Header/H4 */
  font-family: Source Sans Pro;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}

.secondary {
  font-family: "Source Sans 3";
  color: black;
  font-weight: 400;
  font-size: 16px;
  background-color: #e7f8cb;
  background-blend-mode: multiply;
  border: none;
  border-radius: 6px;
  padding: 6px 16px 6px 16px;
  cursor: pointer;
}

button:hover {
  background-color: #6eab21;
}

.button-outline {
  display: flex;
  padding: 8px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 30px;
  border: 2px solid var(--primary-green-200, #77bc1f);
  background: var(--bw-white-0, #fff);
}

.button-outline:hover {
  background-color: #e7f8cb;
}

#sorty {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 500px;
  height: 60px;
  overflow: visible;
}

.error {
  border: 1px solid red;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  display: block;
}
