#quick-hits-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

#quick-hit {
  padding: 12px 20px 10px 20px;
  width: 230px;
  border: 1px solid #d9dadb;
  border-radius: 10px;
}

.img-child {
  width: auto;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1.2);
  filter: grayscale(100%);
}

.cta {
  padding: 7px 34px;
  font-size: 20px;
  border-radius: 30px;
}
